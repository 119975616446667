import payRepository from '@/repository/pay.repository';

//const timer = time => new Promise(resolve => setTimeout(resolve, time));

const state = {
  email_to_register: '',
  blue_snap_token: null
};

const getters = {
  email_to_register: state => state.email_to_register,
  iframe_link: state => state.iframe_link,
  iframe_psd2: state => state.iframe_psd2,
  blue_snap_token: state => state.blue_snap_token
};

const actions = {
  async getFormToken({ state, commit, rootGetters }) {
    const { token } = await payRepository.getForm(
      state.email_to_register,
      rootGetters['configWebStore/getLanguageWeb'].toUpperCase(),
      rootGetters['productStore/getIdProduct']
    );
    commit('setBluesnapToken', token);
  },

  async payOrderBlueSnap({ commit, state }, dataCard) {
    const dataPayment = {
      ...dataCard,
      token: state.blue_snap_token
    };
    try {
      const { access_token, error_message } = await payRepository.processPayment(dataPayment);
      return { access_token, error: error_message };
    } catch (e) {
      return { error: true };
    }
  }
};

const mutations = {
  setEmailPayment(state, email) {
    state.email_to_register = email;
  },
  setBluesnapToken(state, blue_snap_token) {
    state.blue_snap_token = blue_snap_token;
  }
};

export const paymentStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
