import authRepository from '@/repository/auth.repository';

import router from '@/router';

const token = localStorage.getItem('token_session');

const state = {
  loggedIn: token ? true : false
};

const getters = {
  stateAuth: state => state.loggedIn
};

const actions = {
  async login({ commit, dispatch }, { email, password }) {
    return await authRepository
      .login(email, password)
      .then(({ access_token }) => {
        commit('loggedIn', { token: access_token, replace: false });
        dispatch('meStore/getMe', null, { root: true });

        return { success: true };
      })
      .catch(({ response }) => {
        return { error: response.data.message };
      });
  },
  async autoLogin({ commit, dispatch }, access_token) {
    commit('loggedIn', { token: access_token, replace: true });
    await dispatch('meStore/getMe', null, { root: true });
    return { success: true };
  },
  async rememberPassword({ commit }, email) {
    console.log(email);
    return authRepository
      .remember(email)
      .then(({ success, error }) => {
        console.log(success, error);
        return { success, error };
      })
      .catch(e => {
        console.log(e);
        return { error: 'user_not_exists' };
      });
  }
};

const mutations = {
  loggedIn(state, { token, replace = false }) {
    state.loggedIn = true;
    localStorage.setItem('token_session', token);
    if (replace) {
      router.replace({ name: 'Dashboard' });
    } else {
      router.push({ name: 'Dashboard' });
    }
  },

  logout(state) {
    state.loggedIn = false;
    localStorage.removeItem('token_session');
    router.push({ name: 'Home' });
  }
};

export const authStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
