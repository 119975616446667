import { createStore } from 'vuex';

import { authStore } from './auth.store';
import { meStore } from './me.store';

import { paymentStore } from './payment.store';
import { configWebStore } from './configWeb.store';
import { productStore } from './product.store';

export default createStore({
  modules: {
    authStore,
    meStore,
    paymentStore,
    configWebStore,
    productStore
  }
});
