import { createI18n } from 'vue-i18n/index';
import messages from '@/locales/en';

const loadedLanguages = ['en']; // our default language that is preloaded
export const availableLanguages = ['es', 'de', 'da', 'en', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'pt', 'ro', 'sv']; //

export const i18n = createI18n({
  warnHtmlInMessage: 'off', //eliminamos el warning en consola al utilizar html directamente en los localses*.json
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: messages
  }
});

export function setConfi18n() {
  let navlanguage = localStorage.getItem('geo_locale') || navigator.language.slice(0, 2);
  loadLocaleMessages(navlanguage);
  return i18n;
}

export async function loadLocaleMessages(locale) {
  // load locale messages
  if (i18n.locale === locale || loadedLanguages.includes(locale)) {
    _setI18nLanguage(locale);
  } else {
    const messages = await import(/* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    loadedLanguages.push(locale);
    _setI18nLanguage(locale);
  }
}

function _setI18nLanguage(lang) {
  i18n.global.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  document.querySelector("head [http-equiv='Content-Language']").setAttribute('content', lang);
  localStorage.setItem('geo_locale', lang);
}
