import userRepository from '@/repository/user.repository';
import geolocationRepository from '@/repository/geolocation.repository';

const state = {
  user: null,
  userProfileBilling: null,
  userProfilePayments: null,
  userGeolocations: []
};

const getters = {
  getNextPaymentDay: state => {
    const d = new Date(state.userProfilePayments.next_payment_at);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  },
  getLastDayActive: state => {
    const d = new Date(state.userProfilePayments.premium_ends_at);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  },
  isSubscriptionActive: state =>
    state.userProfilePayments?.premium_ends_at == null ? true : false,

  hasBillingDataCompleted: state =>
    state.userProfileBilling == null ? false : true,
  getStaticsGeos: state => {
    const success = state.userGeolocations.filter(g => g.state == 'success');
    const waiting = state.userGeolocations.filter(g => g.state == 'waiting');
    const ignored = state.userGeolocations.filter(g => g.state == 'ignored');
    return {
      success: success.length,
      waiting: waiting.length,
      ignored: ignored.length
    };
  },
  listGeolocations: state => state.userGeolocations,
  getCountryCodeUser: state => state.user?.user_country
};

const actions = {
  async getMe({ commit, dispatch }) {
    try {
      const {
        user,
        profileBilling,
        profilePayments
      } = await userRepository.me();
      if (!user || !user.user_active) {
        return commit('authStore/logout', null, { root: true });
      }
      commit('setUser', user);
      commit('setProfileBilling', profileBilling);
      commit('setUserProfilePayments', profilePayments);
      dispatch('getMeGeolocations');
    } catch (e) {
      //console.log(e);
    }
  },

  async updateMe({ commit, dispatch }, form) {
    try {
      await userRepository.updateMe(form);
    } catch (e) {
      console.log(e);
    }
  },

  async getMeGeolocations({ commit }) {
    const { geolocations } = await geolocationRepository.getAll();
    commit('setGeolocations', geolocations);
  },

  async locatePhone({ dispatch }, phone_number) {
    try {
      await geolocationRepository.createGeolocation(phone_number);
      dispatch('getMeGeolocations');
    } catch (e) {
      console.log(e);
      //TODO
    }
  },

  async cancelMeSubscription({ dispatch }) {
    await userRepository.cancelMeSubscription();
    dispatch('getMe');
  }
};
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setProfileBilling(state, profile) {
    state.userProfileBilling = profile;
  },
  setUserProfilePayments(state, profile) {
    state.userProfilePayments = profile;
  },
  setGeolocations(state, geolocations) {
    state.userGeolocations = geolocations.reverse();
  }
};

export const meStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
