import { ApiAxios } from './repository.config';

const RESOURCE = '/user';

export default {
  async me() {
    const { data } = await ApiAxios().get(`${RESOURCE}/me`);
    return data;
  },

  async updateMe(form) {
    const { data } = await ApiAxios().put(`${RESOURCE}/me`, form);
    return data;
  },
  async mePayments() {
    const { data } = await ApiAxios().get(`${RESOURCE}/me/payments`);
    return data;
  },

  async cancelMeSubscription() {
    const { data } = await ApiAxios().delete(`${RESOURCE}/me`);
    return data;
  }
};
