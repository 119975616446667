import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import SvgIcon from '@jamescoyle/vue-icon';
import { registerComponents } from './plugins/loaderBaseComponents';
import { setConfi18n } from './plugins/i18n';
import VueAnimXyz from '@animxyz/vue3';

import '@animxyz/core'; // Import css here if you haven't elsewhere
import './assets/tailwind-style.css';

//** analytics */
import VueGtag from 'vue-gtag-next';

import gads from '@/plugins/gads';

const app = createApp(App);

registerComponents(app);

app.component('svg-icon', SvgIcon);

app
  .use(store)
  .use(router)
  .use(VueAnimXyz)
  .use(setConfi18n())
  .use(gads, {
    property_id: 'AW-386377553'
  })
  .use(VueGtag, {
    property: { id: 'UA-180732662-4' },
    router
  })
  .mount('#app');
