import { ApiAxios } from './repository.config';

const RESOURCE = '/misc';

export default {
  async getLocationIPUser() {
    const { data } = await ApiAxios().get(`${RESOURCE}/location-ip`);
    return data.country;
  }
};
