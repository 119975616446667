import { ApiAxios } from './repository.config';

const RESOURCE = '/auth';

export default {
  async login(user_email, user_password) {
    const { data } = await ApiAxios().post(`${RESOURCE}/sign-in`, {
      user_email,
      password: user_password
    });
    return data;
  },

  async remember(user_email) {
    const { data } = await ApiAxios().post(`${RESOURCE}/remember-password`, {
      remember_email: user_email
    });
    return data;
  }
};
