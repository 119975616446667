import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Home from "../views/Home.vue";

const Contact = () => import(/* webpackChunkName: "contact" */ "../views/paginas/Contact.vue");
const Unsubscribe = () => import(/* webpackChunkName: "unsubscribe" */ "../views/paginas/Unsubscribe.vue");
const Faq = () => import(/* webpackChunkName: "faq" */ "../views/paginas/Faq.vue");
const Price = () => import(/* webpackChunkName: "price" */ "../views/paginas/Price.vue");
const dashboard_body = () => import(/* webpackChunkName: "dashboard_body" */ "../views/dashboard/index.vue");
const dashboard = () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/dashboard.vue");
const dashboard_profile = () => import(/* webpackChunkName: "dashboard_profile" */ "../views/dashboard/profile.vue");

const createAccount = () => import(/* webpackChunkName: "create-account" */ "../views/process/create-account.vue");
const checkPayment = () => import(/* webpackChunkName: "check-payment" */ "../views/process/check-payment.vue");
const geolocation = () => import(/* webpackChunkName: "geolocation" */ "../views/Geolocation.vue");

const profile_data = () => import(/* webpackChunkName: "dashboard_profile_data" */ "../components/profile/profileData.vue");
const account_data = () => import(/* webpackChunkName: "dashboard_account_data" */ "../components/profile/accountData.vue");
const billing_data = () => import(/* webpackChunkName: "dashboard_billing_data" */ "../components/profile/billingData.vue");

const legal_cookies = () => import(/* webpackChunkName: "legal_cookies" */ "../views/paginas/Cookies.vue");
const legal_privacy = () => import(/* webpackChunkName: "legal_privacy" */ "../views/paginas/Privacy.vue");
const legal_terms = () => import(/* webpackChunkName: "legal_terms" */ "../views/paginas/Terms.vue");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "process-payment",
        name: "ProcessPayment",
        component: checkPayment,
        props: (route) => ({ order_id: route.query.order, payment_id: route.query.payment_id }),
      },
      {
        path: "create-account",
        name: "CreateAccount",
        component: createAccount,
      },
    ],
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: Unsubscribe,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/price",
    name: "Price",
    component: Price,
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: legal_cookies,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: legal_privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: legal_terms,
  },
  {
    path: "/locate-:geolocation_slug",
    name: "GeolocationPage",
    component: geolocation,
    props: true,
  },
  {
    path: "/dashboard",
    component: dashboard_body,
    meta: {
      hide_footer: true,
      required_auth: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: dashboard,
      },
      {
        path: "/profile",
        component: dashboard_profile,
        children: [
          {
            path: "",
            name: "Profile",
            component: profile_data,
          },
          {
            path: "account",
            name: "AccountData",
            component: account_data,
          },
          {
            path: "billing",
            name: "BillingData",
            component: billing_data,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 150,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token_session");
  if (to.meta.required_auth && !loggedIn) {
    return next("/");
  } else {
    next();
  }
});

trackRouter(router);

export default router;
