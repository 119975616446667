import { availableLanguages, loadLocaleMessages } from '@/plugins/i18n';

const phoneLocalstorage = localStorage.getItem('geo_phone');
const languageWeb = localStorage.getItem('geo_locale');
const emailUser = localStorage.getItem('geo_email_user');

const state = {
  countryUser: null,
  languageWeb: languageWeb ?? 'en',
  phoneNumber: phoneLocalstorage ?? null,
  emailUser: emailUser ?? null,
  forceReloadFormPayment: false
};

const getters = {
  getCountry: state => state.countryUser,
  getPhoneDefault: state => state.phoneNumber,
  getLanguageWeb: state => state.languageWeb,
  getEmailDefault: state => state.emailUser,
  hasReloadForPayment: state => state.forceReloadFormPayment
};

const actions = {
  updatePhoneNumber({ commit }, phone) {
    localStorage.setItem('geo_phone', phone);
    commit('setPhoneDefault', phone);
  },
  deletePhoneNumber() {
    localStorage.removeItem('geo_phone');
  },
  setLanguageWeb({ commit }, lang) {
    if (availableLanguages.includes(lang)) {
      loadLocaleMessages(lang);
      commit('setLangWeb', lang);
    }
  },
  updateEmailDefault({ commit }, email) {
    localStorage.setItem('geo_email_user', email);
    commit('setEmailDefault', email);
  },
  updateErrorInPayment({ state, commit }, status) {
    if (status) {
      commit('paymentStore/setEmailPayment', state.emailUser, { root: true });
    }
    commit('setErrorPayment', status);
  }
};

const mutations = {
  setCountryUser(state, country) {
    state.countryUser = country;
  },
  setPhoneDefault(state, phone) {
    state.phoneNumber = phone;
  },
  setLangWeb(state, lang) {
    state.languageWeb = lang;
  },
  setEmailDefault(state, email) {
    state.emailUser = email;
  },
  setErrorPayment(state, status) {
    state.forceReloadFormPayment = status;
  }
};

export const configWebStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
