import { ApiAxios } from './repository.config';

const RESOURCE = '/pay';

export default {
  async getForm(user_email, user_lang, product_id) {
    const { data } = await ApiAxios().post(`${RESOURCE}/form`, {
      product_id: product_id,
      user_email: user_email,
      user_lang: user_lang
    });
    return data;
  },

  async processPayment(dataPayment) {
    const { data } = await ApiAxios().post(`${RESOURCE}/process-payment`, {
      ...dataPayment
    });
    return data;
  },

  async processOrder(order_id) {
    const { data } = await ApiAxios().post(`${RESOURCE}/order`, {
      order_id
    });
    return data;
  },

  async verificationPayment(payment_id) {
    const { data } = await ApiAxios().post(`${RESOURCE}/check-payment`, {
      payment_id
    });
    return data;
  }
};
