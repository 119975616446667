import { ApiAxios } from './repository.config';

const RESOURCE = '/geo';

export default {
  async getAll() {
    const { data } = await ApiAxios().get(`${RESOURCE}`);
    return { geolocations: data };
  },

  async createGeolocation(phone_to_locate) {
    await ApiAxios().post(`${RESOURCE}`, {
      phone_number: phone_to_locate
    });
  },

  async updateLocation(slug, accuracy, latitude, longitude) {
    await ApiAxios().put(`${RESOURCE}/${slug}`, {
      accuracy,
      latitude,
      longitude
    });
  }
};
