import { ref } from 'vue';

const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

export default function verificationEmail() {
  const email = ref('');
  const isValidEmail = () => (reg.test(email.value) ? true : false);
  return {
    email,
    isValidEmail
  };
}
