import { ApiAxios } from './repository.config';

const RESOURCE = '/product';

export default {
  async getProductDefault() {
    const { data } = await ApiAxios().get(RESOURCE);
    return { product: data };
  }
};
