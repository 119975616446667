export default {
  install: (app, options) => {
    function gtag() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(arguments);
    }

    if (typeof window !== 'undefined' && options.property_id !== 'undefined') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//www.googletagmanager.com/gtag/js?id=${options.property_id}`;
      script.async = true;
      document.head.appendChild(script);

      gtag('js', new Date());
      gtag('config', options.property_id);
      app.config.globalProperties.$ownGtagAds = {
        conversion: data => gtag('event', 'conversion', data)
      };
    } else {
      console.log('g_ads_plugin: error not provided property_id');
    }
  }
};
