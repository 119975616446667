import ProductRepository from '@/repository/product.repository';

const parsePrice = price => (price / 100).toFixed(2);

const state = {
  product_id: null,
  product_price_trial: null,
  product_price_suscription: null,
  product_currency: null,
  product_sign: null
};

const getters = {
  getIdProduct: state => state.product_id,
  getPriceTrial: state =>
    `${state.product_sign}${parsePrice(state.product_price_trial)}`,
  getPriceSuscription: state =>
    `${state.product_sign}${parsePrice(state.product_price_suscription)}`
};

const actions = {
  async loadProduct({ commit }) {
    const { product } = await ProductRepository.getProductDefault();
    commit('setProduct', product);
  }
};

const mutations = {
  setProduct(state, product) {
    state.product_id = product._id;
    state.product_price_suscription = product.price_subscription;
    state.product_price_trial = product.price_trial;
    state.product_currency = product.currency;
    state.product_sign = product.sign;
  }
};

export const productStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
